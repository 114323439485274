@import "../../App.scss";
.bottom-searchbar {
  display: none;
}
.seller-navbar-main {
  background-color: $color-secondary;
  width: 100%;
  padding: 24px 40px;
  position: sticky;
  top: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  z-index: 99;
  border-bottom: 1px solid #dddddd;
  .logo-and-search {
    display: flex;
    align-items: center;
    gap: 1rem;
    .menu-icon {
      font-size: 3rem;
      @media (min-width: 1000px) {
        display: none;
      }
    }
    .logo-img {
      width: 6rem;
      height: 3rem;
      object-fit: contain;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 9rem;
    .profile-img {
      width: 2.5rem;
      height: 2.5rem;
      object-fit: cover;
      border-radius: 50%;
    }
    @media (max-width: 1000px) {
      overflow: visible;
      span,
      .icon {
        display: none;
      }
    }
  }
  @media (max-width: 800px) {
    display: flex !important;
  }
  @media (max-width: 600px) {
    padding: 20px;
    display: flex !important;
  }
}
.offcanvas-start {
  .offcanvas-header-dashboard {
    display: flex;
    justify-content: flex-end;
    .logo {
      padding: 15px 0px;
      display: flex;
      justify-content: center;
      flex: 1;
      img {
        width: 100px;
      }
    }
    .icon {
      font-size: 1.8rem;
    }
  }
  .seller-dashboard-offcanvas-body {
    // width: 100vw !important;
    .options {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 12px;
      align-content: flex-start;
      justify-content: space-between;
      // position: fixed;
      // left: 0;
      // top: 0;
      width: fit-content;
      background-color: #ffffff;
      height: 100%;
      z-index: 1;
      .option-inner-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-content: flex-start;
        .chat {
          display: flex;
          justify-content: center;
          gap: 10px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.42px;
        }
        .logo {
          padding: 35px 0px;
          display: flex;
          justify-content: center;
          img {
            width: 100px;
          }
        }
        .option {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.42px;
  
          gap: 10px;
          background: #f9fafb;
  
          cursor: pointer;
          padding: 8px 12px;
          border-radius: 0.2rem;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: $color-primary;
            color: $color-secondary;
            svg {
              // fill: white;
              path {
                fill: white;
              }
            }
          }
          .icon {
            font-size: 1.6rem;
          }
        }
        .active {
          background-color: $color-primary;
          color: $color-secondary;
          svg {
            fill: white;
            path {
              fill: white;
            }
          }
        }
      }
      .logout-support {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .logout {
          display: flex;
          gap: 10px;
          color: #e31e24;
          padding: 12px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.42px;
          cursor: pointer;
  
          svg {
            path {
              fill: #e31e24;
            }
          }
        }
      }
      // @media (max-width: 800px) {
      //   display: none;
      // }
    }
  }
}
