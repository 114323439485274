@import "../../App.scss";

.dashboard-container {
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
  font-size: 1.1rem;
  position: relative;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  .options {
    display: grid;
    padding: 1rem;
    padding-top: 8rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
    align-content: flex-start;
    position: fixed;
    left: 0;
    top: 0;
    width: fit-content;
    background-color: #fff;
    height: 100%;
    z-index: 1;
    .option {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      gap: 0.3rem;
      cursor: pointer;
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      border-radius: 0.2rem;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: $color-primary;
        color: $color-secondary;
      }
      .icon {
        font-size: 1.6rem;
      }
    }
    .active {
      background-color: $color-primary;
      color: $color-secondary;
    }
    @media (max-width: 800px) {
      display: none;
    }
  }
  .right-side {
    background-color: #f3f5f8;
    padding: 3rem 2rem 0 15rem;
    width: 100vw;
    @media (max-width: 800px) {
      padding: 3rem 2rem;
    }
    .dashboard {
      // padding: 3rem 2rem;
      h1 {
        font-size: 2rem;
        font-weight: 600;
      }
      &-content {
        background-color: $color-secondary;
        padding: 1rem;
        border-radius: 0.2rem;
        h3 {
          font-size: 1.7rem;
          font-weight: 600;
        }
        &-box {
          background-color: #fafafa;
          padding: 1rem;
          p {
            font-size: 1.5rem;
            font-weight: 500;
          }
          .dates {
            display: flex;
            gap: 1rem;
            @media (max-width: 460px) {
              gap: 0.3rem;
              flex-direction: column;
            }
          }
          .bt-progress-bar {
            margin-block: 0.6rem;
          }
          .started {
            display: flex;
            align-items: flex-start;
            gap: 0.4rem;
            margin-block: 1rem;
            input {
              accent-color: $color-primary;
              width: 1.2rem;
              height: 1.2rem;
              margin-top: 0.3rem;
            }
            div {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              width: 100%;
              .images {
                display: flex;
                flex-direction: row;
                gap: 0.3rem;
                .image {
                  width: 7rem;
                  height: 5rem;
                  background-color: #d9d9d9;
                }
              }
            }
          }
        }
      }
    }
    .my-orders {
      width: 100%;
      display: flex;
      flex-direction: column;
      .empty-orders {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        .btn-primary {
          padding-inline: 4rem;
          width: fit-content !important;
        }
      }
    }
    .message-div {
      background-color: #f3f5f8;
    }
    .favorites {
      .favorites-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, 15rem);
        gap: 1rem;
        .slider-item {
          height: auto !important;
          background-color: #fff;
          .slider-img {
            margin-top: 1rem;
            height: auto !important;
          }
        }
      }
      .empty-wishlist {
        width: 100%;
        display: flex;
        justify-content: center;
        .empty-box-img {
          height: 75vh;
          object-fit: contain;
          @media (max-width: 600px) {
            width: 90%;
            height: auto;
          }
        }
      }
    }
    .routes {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      margin-bottom: 1rem;
      .underline-none,
      .icon {
        color: #626262;
      }
    }
    .settings {
      // padding: 3rem 2rem;
      .settings-content {
        padding: 2rem;
        background-color: #fff;
        h1 {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        div {
          font-size: 1.3rem;
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
          align-items: center;
        }
        .switch {
          font-size: 1.21rem;
          position: relative;
          display: inline-block;
          width: 3.5em;
          height: 2em;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #8c8f96;
          border: 1px solid #8c8f96;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          border-radius: 2.14rem;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 1.4em;
          width: 1.4em;
          border-radius: 1.42rem;
          left: 0.27em;
          bottom: 0.25em;
          background-color: #fff;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }

        input:checked + .slider {
          background-color: #f5bf7d;
          border: 1px solid #f5bf7d;
        }

        input:focus + .slider {
          -webkit-box-shadow: 0 0 1px #f5bf7d;
          box-shadow: 0 0 1px #f5bf7d;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(1.4em);
          -ms-transform: translateX(1.4em);
          transform: translateX(1.4em);
          background-color: #fff;
        }
      }
    }
    .support-ticket {
      // padding: 3rem 2rem;
      h3 {
        font-size: 2rem;
        font-weight: 600;
      }
      span {
        color: #8c8f96;
      }
      .ticket-form {
        padding: 2rem;
        background-color: #fff;
        form {
          padding: 1rem;
          border-radius: 0.5rem;
          border: 1px solid #c2c2c2;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .input-box {
            display: flex;
            flex-direction: column;
            label {
              font-weight: 600;
            }
            input {
              outline: none;
              width: 100%;
              border: none;
              padding: 0.5rem;
              border-radius: 0.2rem;
              background-color: #f9f9f9;
            }
          }
          .btn-primary {
            align-self: flex-start;
          }
        }
      }
    }
    .edit-profile {
      width: 50vw;
      // padding: 3rem 2rem;
      @media (max-width: 520px) {
        width: 100%;
      }
      .image-div {
        margin: 1rem 0;
        position: relative;
        width: fit-content;
        .profile-img {
          width: 7rem;
          height: 7rem;
          border-radius: 50%;
          object-fit: cover;
          cursor: pointer;
        }
        .icon {
          position: absolute;
          bottom: -0.5rem;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          font-size: 1.3rem;
        }
      }
      form {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.5rem;
        .input-box {
          display: grid;
          grid-template-columns: repeat(1fr);
          span {
            justify-self: flex-end;
            cursor: pointer;
          }
          input {
            outline: none;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
          .disabled{
            background-color: #e0e0e0;
          }
        }
        .buttons {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
          .btn-primary {
            justify-self: flex-start;
            padding-inline: 2rem;
          }
        }
      }
    }
  }
}

.cancel-modal {
  h3 {
    text-align: center;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .radio-options {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .radio {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        input,
        input:checked,
        input:active {
          cursor: pointer;
          accent-color: $color-primary !important;
        }
      }
    }
    .buttons {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;
      button {
        padding-inline: 3rem !important;
      }
    }
  }
}
