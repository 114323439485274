.edit-profile {
  width: fit-content;
  background: white;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  max-width: 870px;

  @media (max-width: 520px) {
    width: 100%;
  }
  h2 {
    padding: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
    margin: 0;
    border-bottom: 1px solid #eaecf0;
  }
  .image-div {
    padding-top: 24px;
    padding-left: 24px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  form {
    .input-wrapper {
      padding: 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .input-box {
        display: flex;
        width: fit-content;
        // flex: 1;
        min-width: 400px;
        flex-direction: column;
        span {
          justify-self: flex-end;
          cursor: pointer;
        }
        label {
          font-size: 16px;
          font-weight: 700;
          line-height: 20.42px;
        }
        input {
          outline: none;
          border: 1px solid #c2c2c2;
          padding: 0.5rem;
          border-radius: 0.2rem;
          //styleName: 16;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.42px;
        }
        .forgot-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .btn-wrapper {
      padding: 12px;
      border-top: 1px solid #eaecf0;
      display: flex;
      justify-content: flex-end;
      .btn-primary {
        justify-self: flex-start;
        padding-inline: 2rem;
      }
      .buttons{
        display: flex;
        gap: 20px;
      }
    }
  }
}
