.art-details {
  display: flex;
  gap: 1rem;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
  padding: 1rem;
  // @media (max-width: 790px) {
  @media (max-width: 960px) {
    flex-direction: column;
  }
  img {
    width: 9rem;
    height: 12rem;
    object-fit: cover;
    cursor: pointer;
    @media (max-width: 450px) {
      width: 100%;
      height: auto;
    }
  }
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 660px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.31px;
      }
      span {
        &:first-of-type {
          font-size: 16px;
          font-weight: 600;
          line-height: 20.42px;
        }
        &:last-of-type {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.42px;
        }
      }
    }
    .buttons {
      justify-self: flex-end;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .btn-primary {
        display: none;
      }
      @media (max-width: 450px) {
        width: 100%;
        .button {
          width: 100%;
        }
        .btn-primary {
          display: inline;
        }
      }
    }
  }
}
