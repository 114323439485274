.cart-shipping {
  .main-div {
    background-color: #fff;
    border: 1px solid #c2c2c270;
    border-radius: 0.5rem;
    .form-side {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      // @media (min-width: 521px) {
      //   width: 55vw;
      // }
      @media (max-width: 800px) {
        width: 100%;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.1rem;
        padding: 1rem;
        .inputs {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;

          @media (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
          }
          .input-box {
            display: flex;
            flex-direction: column;
            &:last-of-type {
              grid-column: 1 / span 2;
              @media (max-width: 600px) {
                grid-column: auto;
              }
            }
            input {
              outline: none;
              border: 1px solid #c2c2c2;
              padding: 0.5rem;
              border-radius: 0.2rem;
            }
          }
        }
        .buttons {
          display: flex;
          gap: 1rem;
          button {
            width: fit-content;
            align-self: flex-start;
            padding-inline: 2rem !important;
          }
        }
      }
    }
  }
}
.empty-addresses {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .empty-img {
    width: 100%;
    height: 100vh;
    object-fit: contain;
    margin-top: -8rem;
    @media (max-width: 600px) {
      margin-top: -10rem;
    }
  }
}
.add-button {
  padding: 12px;
  display: flex;
  justify-content: end;
  margin-top: 0px !important;
  border-top: 1px solid #eaecf0;

  button {
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-radius: 6px;
  }
}
