.profile-setting-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .page-option {
    display: flex;
    gap: 24px;
  }
  .seller-profile {
    // position: relative;
    background: #fbf8f6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .seller-form-wrapper {
      max-width: 100%;
      padding: 24px;
      // margin: 24px;
      width: 100%;
      background: white;
      border-radius: 12px;
      border: 1px solid #eaecf0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      // margin-top: 90px;
      // margin-bottom: 90px;
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 30.62px;
      }
      .upload-logo {
        .image-div {
          h5 {
            font-weight: 700;
          }
          // padding-top: 24px;
          // padding-left: 24px;
          img {
            width: 100px !important;
            height: 100px !important;
          }
        }
        .upload-file-button {
          font-size: 12px;
          font-weight: 500;
          line-height: 15.31px;
          padding: 30px;
          gap: 0px;
          border-radius: 6px;
          border: 1px solid #eaecf0;
          background: #ffffff;
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
        }
      }
      form {
        .input-container {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .upload-file-button {
              background: #f9f9f9;
              padding: 12px 16px 12px 16px;
              border-radius: 4px;
              width: fit-content;
            }
            label {
              font-size: 16px;
              font-weight: 700;
              line-height: 20.42px;
            }
            input,
            textarea,
            select {
              padding: 12px 16px 12px 16px;
              border-radius: 4px;
              border: 1px solid #d0d5dd;
              font-size: 16px;
              font-weight: 400;
              line-height: 20.42px;
            }
            .input-btn-container{
              display: flex;
              gap: 10px;
  
              input{
                width: 100%;
              }
              button{
                text-wrap: nowrap;
                min-width: 80px;
                font-size: 12px;
              }
            }
          }
          .gender {
            .gender-input-wrapper {
              display: flex;
              gap: 4px;
            }
            display: flex;
            gap: 8px;

            .input-wrapper {
              gap: 8px;

              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              label {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.25px;
                color: #838799;
              }
              input {
                width: 15px;
                height: 15px;
                &:checked {
                  accent-color: black;
                }
              }
            }
          }
          .address {
            display: flex;
            gap: 12px;
            .input-wrapper {
              flex: 1;
              select {
                font-weight: 700;
                color: #838799;
                width: 100%;
              }
            }

          }
          @media (max-width: 1100px){
           .address{
            flex-direction: column;
           } 
          }
          .email-notification {
            .input-wrapper {
              gap: 8px;

              display: flex;
              flex-direction: row;
              align-items: center;
              label {
                font-size: 16px;
                font-weight: 400;
                line-height: 20.42px;
              }
              input {
                width: 15px;
                height: 15px;
                &:checked {
                  accent-color: black;
                }
              }
            }
          }
          .product-type-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            label {
              font-size: 16px;
              font-weight: 400;
              line-height: 20.42px;
            }
            .product-type {
              display: flex;
              gap: 12px;
              div {
                text-align: center;
              }
            }
          }
          .btn-wrapper {
            display: flex;
            justify-content: flex-end;
            button {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}

.notification-setting {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item {
      border-radius: 4px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #eaecf0;
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.42px;
        color: black;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        &.round {
          border-radius: 34px;
          &:before {
            border-radius: 50%;
          }
        }
      }
      input {
        &:checked {
          & + .slider {
            background-color: #069952;
            &:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }
          }
        }
        &:focus {
          & + .slider {
            box-shadow: 0 0 1px #069952;
          }
        }
      }
    }
    .btn-wrapper {
      display: flex;
      margin-top: 20px;
      justify-content: end;
    }
  }
}

@media (max-width: 400px) {
  .profile-setting-container {
    .page-option {
      flex-direction: column;
      gap: 10px;
    }
  }
}
