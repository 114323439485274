.more-from-artist {
  padding: 40px 0px;
  .row {
    width: 100%;
    display: flex;
    padding: 0px 40px;
    // height: 365px;
    @media (max-width:900px) {
      gap: 40px;      
    }
    @media (max-width:500px) {
      padding: 0px;
    }
    .left-column {
      width: 35%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width:1200px) {
        width: 45%;
        
      }
      @media (max-width:900px) {
        width: 100%;
        
      }
      .profile-card {
        margin: auto;
        width: min(408px, 100%);
        border: 1px solid #eaecf0;

        border-radius: 8px;
        .title {
          border-radius: 8px 8px 0px 0px;
          background: #dd5313;
          color: white;
          font-family: Space Grotesk;
          font-size: 20px;
          font-weight: 400;
          line-height: 25.52px;
          text-align: center;
          padding: 12px 0px;
        }
        .artist-deatil-wrapper {
          position: relative;
          .background {
            img {
              width: 100%;
              height: 195px;
              gap: 0px;
            }
          }
          .profile-img-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 130px;
            height: 130px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            .profile-img {
              width: 82px;
              height: 82px;
              background-size: cover;
              background-position: center;
            }
          }
          .artist-detail {
            height: 195px;
            padding-top: 70px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .name {
              font-family: Space Grotesk;
              font-size: 24px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
            }
            .shop-name {
              font-family: Space Grotesk;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
            }
            .artist-review {
              display: flex;
              padding: 24px;
              width: 100%;
              justify-content: space-around;
              .count {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                strong {
                  font-family: Space Grotesk;
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 30.62px;
                  text-align: left;
                }
                span {
                  font-family: Space Grotesk;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 15.31px;
                  text-align: left;
                }
              }
            }
          }
        }
        .button-wrapper {
          border: 1px solid #eaecf0;

          padding: 12px;
          width: 100%;
          border-radius: 0px 0px 8px 8px;

          button {
            font-family: Space Grotesk;
            font-size: 16px;
            font-weight: 700;
            line-height: 20.42px;
            text-align: center;
            width: 100%;
            border-radius: 6px;
          }
        }
      }
    }
    .right-column {
      width: 65%;
      padding-bottom: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media (max-width:1200px) {
        width: 55%;
        
      }
      @media (max-width:900px) {
        width: 100%;
        
      }
      .title {
        font-family: Space Grotesk;
        font-size: 24px;
        font-weight: 500;
        line-height: 30.62px;
        text-align: left;
      }

      overflow: auto;
      .slider-wrapper {
        display: flex;
      }
    }
  }
}
