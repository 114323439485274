.support-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .page-option {
    display: flex;
    gap: 24px;
  }
  .seller-support {
    // position: relative;
    background: #fbf8f6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .seller-form-wrapper {
      max-width: 100%;
      padding: 24px;
      // margin: 24px;
      width: 100%;
      background: white;
      border-radius: 12px;
      border: 1px solid #eaecf0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      // margin-top: 90px;
      // margin-bottom: 90px;
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 30.62px;
      }
      .upload-logo {
        .upload-file-button {
          font-size: 12px;
          font-weight: 500;
          line-height: 15.31px;
          padding: 30px;
          gap: 0px;
          border-radius: 6px;
          border: 1px solid #eaecf0;
          background: #ffffff;
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
        }
      }
      form {
        .input-container {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .row-wrapper {
            display: flex;
            gap: 16px;
            .input-wrapper {
              width: 100%;
            }
          }
          @media (max-width: 600px){
            .row-wrapper{
              flex-direction: column;
            }
          }
          .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .upload-file-button {
              background: #f9f9f9;
              padding: 12px 16px 12px 16px;
              border-radius: 4px;
              width: fit-content;
            }
            label {
              font-size: 16px;
              font-weight: 700;
              line-height: 20.42px;
            }
            input,
            textarea,
            select {
              padding: 12px 16px 12px 16px;
              border-radius: 4px;
              border: 1px solid #d0d5dd;
              font-size: 16px;
              font-weight: 400;
              line-height: 20.42px;
            }
          }
          .gender {
            display: flex;
            gap: 4px;

            .input-wrapper {
              gap: 8px;

              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              label {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.25px;
                color: #838799;
              }
              input {
                width: 15px;
                height: 15px;
                &:checked {
                  accent-color: black;
                }
              }
            }
          }
          .address {
            display: flex;
            gap: 12px;
            .input-wrapper {
              flex: 1;
              select {
                font-weight: 700;
                color: #838799;
              }
            }
          }
          .email-notification {
            .input-wrapper {
              gap: 8px;

              display: flex;
              flex-direction: row;
              align-items: center;
              label {
                font-size: 16px;
                font-weight: 400;
                line-height: 20.42px;
              }
              input {
                width: 15px;
                height: 15px;
                &:checked {
                  accent-color: black;
                }
              }
            }
          }
          .product-type-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            label {
              font-size: 16px;
              font-weight: 400;
              line-height: 20.42px;
            }
            .product-type {
              display: flex;
              gap: 12px;
              div {
                text-align: center;
              }
            }
          }
          .btn-wrapper {
            display: flex;
            justify-content: flex-end;
            button {
              width: 100%;
            }
          }
        }
      }
      .contact-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        h3 {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.42px;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 17.86px;
        }
      }
    }
  }
}
