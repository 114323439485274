@import "../../../App.scss";

.become-seller-main {
  .header {
    height: fit-content;
    padding: 3rem 2rem;
    background-position: center;
    background-size: contain;
    font-size: 1.1rem;
    .content {
      background-color: #fff;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        @media (max-width: 980px) {
          gap: 0;
        }
        h1 {
          //styleName: 32;
          font-size: 32px;
          font-weight: 500;
          line-height: 40.83px;
        }
        p {
          max-width: 800px;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.86px;
        }
        gap: 16px;
        text-align: center;
        justify-content: center;
        align-items: center;
        button {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.42px;
        }
        @media (max-width: 600px) {
          h1 {
            font-size: 20px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
  .how-it-work {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    h1 {
      font-size: 32px;
      font-weight: 500;
      line-height: 40.83px;
      margin: 0;
    }
    .steps {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 900px;
      justify-content: space-around;

      .step-wrapper {
        max-width: 247px;
        display: flex;
        gap: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        h4 {
          font-size: 24px;
          font-weight: 500;
          line-height: 30.62px;
          margin: 0;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15.31px;
          text-align: center;
          color: #8c8f96;
        }
      }
    }
    @media (max-width: 600px) {
      h1 {
        font-size: 24px;
      }
      .steps {
        gap: 12px;
        .step-wrapper {
          img {
          }
          h4 {
            font-size: 16px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
 
  .seller-community {
    padding: 3rem 2rem;
    h1 {
      font-size: 2.2rem;
      font-weight: 500;
    }
    p {
      width: 60%;
      @media (max-width: 980px) {
        width: 100%;
      }
    }
    .seller-members {
      margin-top: 2rem;
      display: flex;
      overflow: scroll;
      padding-bottom: 20px;
      // grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      gap: 1rem;
      justify-items: center;
      @media (max-width: 938px) {
        // grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      }
      @media (max-width: 812px) {
        display: flex;
        overflow: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .artist {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        min-width: 250px;
        img {
          width: 100%;
          @media (max-width: 812px) {
            width: 18rem;
            object-fit: cover;
          }
        }
        @media (max-width: 812px) {
          min-width: unset;
        }
        span {
          &:first-of-type {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .faq {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 100px 40px;
    @media (max-width:600px) {
      padding: 3rem 2rem;

      
    }
    .row {
      width: 100%;
      display: flex;
      // padding: 0px 40px;
      // height: 365px;
      .left-column {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 500;
          line-height: 30.62px;
          text-align: left;
          color: #1f1f1f;
        }
        .contatnt {
          color: #000000;

          //styleName: 32;
          font-family: Space Grotesk;
          font-size: 32px;
          font-weight: 500;
          line-height: 40.83px;
          text-align: left;
        }
      }
      .right-column {
        width: 50%;
        padding-bottom: 20px;
        height: 100%;

        overflow: auto;
        .faq {
          display: flex;
          flex-direction: column;
        }
      }
      @media (max-width: 1000px) {
        height: fit-content;
        flex-direction: column;
        gap: 40px;
        .left-column {
          width: 100%;
          .title {
            font-size: 18px;
          }
          .contatnt {
            font-size: 24px;
          }
        }
        .right-column {
          width: 100%;
        }
      }
      @media (max-width: 600px) {
        // padding: 0px 20px;
        .left-column {
          width: 100%;
        }
      }
    }
    .side-img {
      position: absolute;
      left: -40px;
      bottom: -40px;
      img {
        transform: rotateY(180deg);
        width: 20vw;
        @media (max-width: 1000px) {
          width: 25vw;
        }
        @media (max-width: 600px) {
          right: -40px;
          left: unset;
          width: 30vw;
        }
      }
      // angle: -180 deg;
    }
  }
}
