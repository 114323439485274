.invoice-header-offcanvas {
  display: flex;
  align-items: center;
  // background-color: #111;
  color: black;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
    margin: 0;
  }

  // border-radius: 12px;
  .icon {
    cursor: pointer;
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
  }
}

.modal-body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #000;
  .profile_img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

//invoice modal
.underline-invoice-header {
  height: 1px;
  width: 100%;
  background-color: #C8C8C8;
}
.invoice-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px 16px 16px !important;
  color: #000;

  .fw-500 {
    font-weight: 500;
    font-size: 1.1rem;
  }
  .order-id {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.42px;
  }
  .deliver-date {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.42px;
  }
  .address {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.42px;
    }
    &:nth-child(2) {
      text-transform: uppercase;
    }
  }
  .product-details {
    display: flex;
    gap: 0.8rem;
    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20.42px;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.31px;
      }
    }
    .quantity {
      font-size: 16px;
      font-weight: 500;
      line-height: 20.42px;
    }
    .price {
      font-size: 24px;
      font-weight: 500;
      line-height: 30.62px;
    }
    img {
      height: 9rem;
      width: 8rem;
      object-fit: cover;
    }
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
  .order-summary {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .sub-total {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.31px;
    }
    .total {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
      .h5 {
        display: flex;
        flex-direction: column;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 15.31px;
        }
      }
      .amount{
        text-align: right;
      }
    }
  }
}
