@import "../../../../App.scss";

.accordion-box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  .question {
    padding: 0.7rem;
    background-color: $color-secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0px 0px ;
    border: 1px solid #EAECF0;
    border-bottom: none;

    .icon {
      color: #6b6f80;
    }
  }
  #accordion-description {
    padding: 0.7rem;
    background-color: $color-secondary;
    border: 1px solid #EAECF0;
    border-top: none;

    padding-left: 0.5rem;
    border-radius: 0px 0px 6px 6px;
  }
}
