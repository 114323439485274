@import "src/App.scss";

.main {
  font-size: 1.1rem;
  padding: 40px;
  background: #fbf8f6;
  min-height: 70vh;
  @media (max-width: 600px) {
    padding: 20px;
  }
  .pages {
    display: flex;
    margin-block: 2rem;
    gap: 24px;
    overflow-x: scroll;
    div {
      padding-bottom: 0.3rem;
      width: 10rem;
      text-align: center;
      border-radius: 12px;
      border: 1px solid #eaecf0;
      cursor: pointer;
      background: white;
      padding: 12px 24px;
      width: fit-content;
      span {
        font-size: 1.3rem;
        font-weight: 500;
        text-wrap: nowrap;
      }
    }
    .active {
      // border-bottom: 1px solid #52382b;                                          z ccccmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxss
      color: #52382b;
      background: black;
      color: white;
    }
  }
  .edit-profile {
    width: fit-content;
    background: white;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    max-width: 870px;

    @media (max-width: 520px) {
      width: 100%;
    }
    h2 {
      padding: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
      margin: 0;
      border-bottom: 1px solid #eaecf0;
    }
    form {
      .input-wrapper {
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        .input-box {
          display: flex;
          width: fit-content;
          // flex: 1;
          min-width: min(400px, 100%);
          flex-direction: column;
          span {
            justify-self: flex-end;
            cursor: pointer;
          }
          input {
            outline: none;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
          .forgot-wrapper {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .btn-wrapper {
        padding: 12px;
        border-top: 1px solid #eaecf0;
        display: flex;
        justify-content: flex-end;
        .btn-primary {
          justify-self: flex-start;
          padding-inline: 2rem;
        }
      }
    }
  }
  .favorites {
    width: fit-content;
    background: white;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    max-width:100%;
    width: 100%;
    h2 {
      padding: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
      margin: 0;
      border-bottom: 1px solid #eaecf0;
    }
    .empty-wishlist{
      img{
        width: 100%;
      }
    }
    .favorites-list {
      grid-column: 2 / span 4;
      display: grid;
      padding: 2rem;

      grid-template-columns: repeat(3, 1fr);
      // grid-template-columns: repeat(auto-fit, minmax(14rem, 15rem));
      gap: 1rem;
      height: fit-content !important;
      @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 975px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 935px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (max-width: 497px) {
        padding: 0;

        grid-template-columns: repeat(1, 1fr);
      }
      @media (max-width: 975px) {
        grid-column: auto;
      }
      .slider-item {
        height: fit-content !important;
        .slider-img {
          // height: 20rem !important;
          height: 19rem !important;
          // height: 25rem !important;
          @media (max-width: 497px) {
            height: 22rem !important;
          }
        }
      }
    }
  }
}
.my-orders {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: white;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  max-width: 1290px;

  .empty-orders {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  h2 {
    padding: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
    margin: 0;
    border-bottom: 1px solid #eaecf0;
  }
  .delivery-info-wrapper {
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 12px;

    h1 {
      padding: 12px;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
      border-bottom: 1px solid #eaecf0;
    }
  }
}
