.support-ticket {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .header {
    padding: 0px !important;
    align-items: center;
  }
  .ticket-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ticket-card {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border: 1px solid #EAECF0;
      padding: 12px;
      border-radius: 6px;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.86px;
        margin: 0;

        .text-green {
          color: #069952;
        }
        .text-red{
          color: #e31e24;
        }

      }
    }
  }
}
