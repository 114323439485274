.art-work-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .search-bar-wrapper {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    gap: 12px;
    .category {
      flex: 1;
      select {
        width: 100%;
        height: 48px;
        border: 1px solid #eaecf0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.25px;
        border-radius: 4px;
        padding: 0px 10px;
      }
    }
    .search-bar {
      flex: 2;
      display: flex;
      gap: 12px;
      .search-bar-inner-wrapper {
        border: 1px solid #eaecf0;
        border-radius: 4px;
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0px 12px;

        input {
          width: 100%;
          height: 1005;
          border: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.25px;
          border-radius: 4px;
          padding-right: 12px;
          outline: none;
        }
      }
      button {
        height: 48px;
      }
    }
  }
  .filter {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  .list {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .list-header {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 25.52px;
      }
    }
    .table-container {
      max-width: calc(100vw - 390px);
      overflow-x: scroll;
      table {
        thead {
          width: 100%;
          th {
            width: 100%;
            height: 100%;
            padding: 6px 12px;
            border: 1px solid #eaecf0;
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid #eaecf0;
              padding: 12px;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.86px;
              text-align: center;
              .product-detail-wrapper {
                display: flex;
                gap: 10px;
                img {
                  width: 60px;
                  height: 60px;
                }
                .product-detail {
                  display: flex;
                  flex-direction: column;
                  gap: 6px;
                  justify-content: center;
                  align-items: flex-start;
                  .name {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17.86px;
                  }
                  .category {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15.31px;
                  }
                }
              }
              .green-text {
                color: #069952;
              }
              .red-text {
                color: #e31e24;
              }
              .action-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: #2571fa;
                span{
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .art-work-list{
    .list{
      .table-container{
        max-width: calc(100vw - 130px);
      }
    }
  }
}
