@import "../../../../App.scss";

.main-message-container {
  // margin: 3rem 2rem;
  .routes {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    margin-bottom: 1rem;
    .underline-none,
    .icon {
      color: #626262;
    }
  }
  .message {
    background-color: $color-secondary;
    font-size: 1.2rem;
    .head {
      padding: 1rem;
      border-bottom: 1px solid #c2c2c2;
      span {
        font-size: 1.7rem;
        font-weight: 600;
      }
    }
    .message-main {
      display: grid;
      grid-template-columns: 20vw 1fr;
      height: 70vh;
      overflow: hidden;
      @media (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .chat-list {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
        overflow: scroll;
        scroll-behavior: smooth;
        height: 70vh;
        &::-webkit-scrollbar {
          display: none;
        }
        .user {
          display: flex;
          padding: 0.6rem 2rem 0.6rem 0.6rem;
          gap: 0.3rem;
          border-bottom: 1px solid #c2c2c2;
          align-items: center;

          img {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: cover;
          }
          .user-info {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            span {
              display: block;
              line-height: 1.3rem;
            }
            :first-child {
              font-size: 1.2rem;
              font-weight: 600;
            }
            :last-child {
              font-size: 1rem;
              font-weight: 400;
              color: #5f6368;
            }
          }
        }
        .active {
          border-left: 1px solid $color-primary;
        }
      }
      @media (min-width: 1001px) {
        .sm-view-chat,
        .sm-view {
          display: none !important;
        }
        .lg-view {
          display: flex !important;
        }
        .lg-view-chat {
          display: block !important;
        }
      }
      @media (max-width: 1000px) {
        .sm-view {
          display: flex !important;
        }
        .sm-view-chat {
          display: block !important;
        }
        .lg-view-chat,
        .lg-view {
          display: none !important;
        }
      }
      .user-chat {
        display: block;
        position: relative;
        height: 70vh;
        .chat-head {
          display: flex;
          padding: 0.6rem 2rem 0.6rem 0.6rem;
          gap: 0.3rem;
          border-bottom: 1px solid #c2c2c2;
          align-items: center;
          height: 10vh;
          img {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: cover;
          }
          .user-info {
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            span {
              display: block;
              line-height: 1.3rem;
            }
            :first-child {
              font-size: 1.2rem;
              font-weight: 600;
            }
          }
        }
        .chat-messages-div {
          height: 60vh;
          .messages-content {
            height: 60vh;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 1rem;
            overflow: scroll;
            scroll-behavior: smooth;
            padding-bottom: 5rem;
            &::-webkit-scrollbar {
              display: none;
            }
            .left,
            .right {
              padding: 0 0.9rem;
              max-width: 80%;
              .message-content {
                display: flex;
                flex-direction: column;
                small {
                  align-self: flex-end;
                }
              }
            }

            .left {
              align-self: flex-start;
              span {
                padding: 0.8rem;
                border-radius: 0.3rem;
                color: $color-primary;
                background-color: #f7f7f8;
              }
            }
            .right {
              align-self: flex-end;
              span {
                padding: 0.8rem;
                border-radius: 0.3rem;
                color: $color-secondary;
                background-color: $color-primary;
              }
            }
          }
          .chat-input {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: #fff;
            .input-box {
              padding: 1rem 1rem;
              border-radius: 0.5rem;
              display: flex;
              background-color: #eaebf0;
              width: 98%;
              margin: 0.5rem auto;
              input {
                width: 100%;
                background-color: #eaebf0;
                border: none;
                outline: none;
                &:focus {
                  background-color: #eaebf0;
                }
              }
              .buttons {
                display: flex;
                gap: 0.7rem;
                .icon {
                  font-size: 1.6rem;
                  cursor: pointer;
                  color: #787878;
                }
              }
            }
          }
        }
      }
    }
  }
}
