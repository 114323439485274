@import "src/App.scss";

.main-container {
  padding: 1.5rem;
  font-size: 1.1rem;
  background: #fbf8f6;
  hr {
    color: #eaecf0;
    opacity: 1;
  }
  .routes {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    .underline-none,
    .icon {
      color: #626262;
    }
  }
  .empty-img-div {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      height: 75vh;
      object-fit: contain;
      @media (max-width: 600px) {
        width: 90%;
        height: auto;
      }
    }
  }
  .checkout-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 3rem;
    padding: 3rem 0;
    @media (max-width: 1000px) {
      grid-template-columns: 1fr !important;
      gap: 0;
    }
    h1 {
      font-size: 2rem;
      font-weight: 500;
    }
    .form-side {
      .delivery-info-wrapper {
        background: #ffffff;
        border: 1px solid #eaecf0;
        border-radius: 12px;

        h1 {
          padding: 12px;
          margin: 0;
          font-size: 20px;
          font-weight: 500;
          line-height: 25.52px;
          border-bottom: 1px solid #eaecf0;
        }
      }
      .shipping-info-wrapper {
        background: #ffffff;
        border: 1px solid #eaecf0;
        border-radius: 12px;
        h1 {
          font-size: 20px;
          border-bottom: 1px solid #eaecf0;
          font-weight: 500;
          line-height: 25.52px;
          padding: 12px;
        }
        .delivery-date {
          padding: 12px;
          border-bottom: 1px solid #eaecf0;
          h4 {
            font-size: 14px;
            font-weight: 400;
            line-height: 17.86px;
            color: #1f1f1f;
            margin: 0;
          }
          span {
            //styleName: 12;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.31px;
            color: #838799;
          }
        }
        .address {
          padding: 12px;
          border-bottom: 1px solid #eaecf0;
          color: #1f1f1f;
          display: flex;
          flex-direction: column;
          gap: 6px;

          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
          }
          .name {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
          }
          .address-detail {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
      .payment-info-wrapper {
        background: #ffffff;
        border: 1px solid #eaecf0;
        border-radius: 12px;
        h1 {
          font-size: 20px;
          border-bottom: 1px solid #eaecf0;
          font-weight: 500;
          line-height: 25.52px;
          padding: 12px;
        }
        h4 {
          padding: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
        }
      }

      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      .shipping {
        .add-button {
          margin-top: 0 !important;
        }
        .address-div,
        .address-side {
          width: 100% !important;
        }
        .main-div {
          grid-template-columns: none !important;
        }
      }
    }
    .summary {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border: 1px solid #eaecf0;
      padding: 12px;
      border-radius: 12px;
      background: #ffffff;
      h1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 25.52px;
        margin: 0px;
      }

      // width: 48%;
      // position: fixed;
      // right: 1.5rem;
      @media (max-width: 1050px) {
        grid-column: 1 / span 2;
      }
      .order-details {
        // padding: 1rem;
        border-radius: 0.5rem;
        // border: 1px solid #c2c2c270;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        border-radius: 12px;
        .item {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          border: 1px solid #eaecf0;
          padding: 12px;
          border-radius: 12px;
          .details {
            display: flex;
            gap: 20px;
            height: 100%;
            @media (max-width: 600px) {
              // display: grid;
              // grid-template-columns: 1fr 1fr;
              display: flex;
              justify-content: space-between;
            }
            img {
              width: 130px;
              height: 130px;
              object-fit: cover;
              @media (max-width: 600px) {
                width: 12rem;
                height: 20rem;
              }
              @media (max-width: 420px) {
                width: 9rem;
                height: 16rem;
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              // width: 100%;
              flex: 1;
              .grid {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 1rem;

                @media (max-width: 600px) {
                  grid-template-columns: repeat(1, 1fr);
                  justify-items: flex-start !important;
                  padding-left: 2rem;
                  @media (max-width: 400px) {
                    padding-left: 0.5rem;
                  }
                }
                div {
                  .product-title {
                    color: #1f1f1f;
                    font-family: Space Grotesk;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20.42px;
                    text-align: left;
                  }
                  .product-price {
                    //styleName: Text;
                    font-family: Space Grotesk;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 30.62px;
                    text-align: right;
                    color: #1f1f1f;
                  }
                  .category {
                    font-family: Space Grotesk;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15.31px;
                    text-align: left;
                    color: #616161;
                  }
                  .emi {
                    color: #1f1f1f;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15.31px;
                  }
                  &:first-of-type {
                    grid-column: 1 / span 2;
                    @media (max-width: 600px) {
                      grid-column: auto;
                    }
                  }
                  &:last-of-type {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    grid-column: 4 / span 5;
                    margin-bottom: -1rem;
                    @media (max-width: 600px) {
                      grid-column: auto;
                    }
                  }
                  @media (max-width: 600px) {
                    &:nth-of-type(2),
                    &:last-of-type {
                      justify-self: flex-end;
                      padding-right: 1rem;
                    }
                  }
                  .quantity {
                    padding: 0.4rem 1rem;
                    background: #f7f7f7;
                    border: none;
                    outline: none;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20.42px;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    option:checked {
                      background-color: #c2c2c2;
                    }
                  }
                  // .quantity {
                  //   display: flex;
                  //   flex-direction: row;
                  //   gap: 0.7rem;
                  //   align-items: center;
                  //   font-size: 1.7rem;
                  //   span {
                  //     font-size: 1.8rem;
                  //   }
                  //   .icon {
                  //     cursor: pointer;
                  //     color: #bebebe;
                  //   }
                  // }
                  @media (max-width: 600px) {
                    .product-title {
                      font-size: 2rem !important;
                    }
                    .product-price {
                      font-size: 1.6rem !important;
                    }
                    select {
                      padding: 0.8rem 3rem !important;
                      option:checked {
                        background-color: #c2c2c2;
                      }
                    }
                  }
                }
              }
              .remove {
                color: #e31e24;
                background: #f2f4f7;
                font-size: 16px;
                font-weight: 500;
                line-height: 20.42px;
                text-align: right;
                padding: 8px 12px;
                border-radius: 6px;

                align-self: flex-end;
                cursor: pointer;
                @media (max-width: 600px) {
                  padding-right: 1rem;
                  font-size: 1.5rem;
                }
              }
            }
          }
          .art-protection {
            padding: 1rem;
            border-radius: 0.8rem;
            border: 1px solid #c2c2c270;
            display: flex;
            flex-direction: column;
            h3 {
              font-size: 1.5rem;
            }
            h4 {
              font-size: 1.3rem;
            }
            p {
              span {
                text-decoration: underline;
                color: #467fe4;
                cursor: pointer;
              }
            }
            .remove {
              color: #e31e24;
              background: #f2f4f7;
              align-self: flex-end;
              cursor: pointer;
              @media (max-width: 600px) {
                font-size: 1.5rem;
              }
            }
          }
        }
        .art-protection {
          padding: 1rem;
          border-radius: 0.8rem;
          border: 1px solid #c2c2c270;
          display: flex;
          flex-direction: column;
          h3 {
            font-size: 16px;
            font-weight: 700;
            line-height: 20.42px;
            margin-bottom: 10px;
          }
          h4 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.42px;
            margin: 0;
          }
          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;

            span {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-decoration: underline;
              color: #467fe4;
              cursor: pointer;
            }
          }
          .remove {
            color: #467fe4;
            background: #f2f4f7;
            align-self: flex-end;
            cursor: pointer;
            border-radius: 6px;
            padding: 8px 12px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.42px;

            @media (max-width: 600px) {
              font-size: 1.5rem;
            }
          }
        }
        .total-wrapper {
          border: 1px solid #eaecf0;
          padding: 12px;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .flex-between {
            h4 {
              font-size: 20px;
              font-weight: 500;
              line-height: 25.52px;

              color: #1f1f1f;
              margin: 0;
            }
            .right {
              text-align: end;
            }
            font-size: 12px;
            font-weight: 500;
            line-height: 15.31px;
            color: #1f1f1f;
          }
          .link {
            display: flex;
            color: #467fe4;
            text-decoration: underline;
            justify-content: end;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.42px;
          }
        }
      }
    }
  }
}


