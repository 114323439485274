@import "src/App.scss";

.setting-details-div {
  border-radius: 0.2rem;
  border: 1px solid #f7f7f7;
  background-color: #fff;
  margin-bottom: 1rem;
  .summary-details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    // background-color: #f7f7f7;
    background-color: #f7f7f7;
    padding: 1rem;
    cursor: pointer;
    @media (max-width: 861px) {
      gap: 0.5rem;
      flex-direction: column;
      .icon {
        order: -1 !important;
        align-self: flex-end;
      }
    }
  }
  .expanded-order-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 20.42px;
      padding: 12px;
      border: none;
    }
    .bottom-buttons {
      display: flex;
      gap: 2rem;
      justify-content: center;
      .btn-cancel,
      .btn-secondary,
      .btn-primary {
        width: fit-content;
        align-self: center;
        margin-top: 1rem;
      }
    }
    p {
      font-size: 1.2rem;
      font-weight: 600;
      @media (max-width: 861px) {
        text-align: center;
      }
    }
  }
}
