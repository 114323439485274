.order-detail-container {
  padding: 24px;
  // margin: 24px;
  width: 100%;
  background: white;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // margin-top: 90px;
  // margin-bottom: 90px;
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 30.62px;
  }
  form {
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .row {
        display: flex;
        flex-direction: row;
        .input-wrapper {
          width: unset;
          flex: 1;
          .input-inner-wrapper {
            display: flex;
            border: 1px solid #d0d5dd;
            border-radius: 4px;
            align-items: center;

            input {
              flex: 1;
              border: none;
            }
            span {
              padding: 0px 10px;
              font-weight: 600;
              color: #838799;
            }
          }
        }
      }
      .upload-file {
        .upload-file-button {
          font-size: 12px;
          font-weight: 500;
          line-height: 15.31px;
          padding: 30px;
          gap: 0px;
          border-radius: 6px;
          border: 1px solid #eaecf0;
          background: #ffffff;
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
          margin-top: 5px;
        }
      }
      .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .input-inner-wrapper {
          display: flex;
          border: 1px solid #d0d5dd;
          border-radius: 4px;
          align-items: center;

          input {
            flex: 1;
            border: none;
          }
          span {
            padding: 0px 10px;
            font-weight: 600;
            color: #838799;
          }
        }
        .upload-file-button {
          background: #d0d5dd;
          padding: 12px 16px 12px 16px;
          border-radius: 4px;
          width: fit-content;
        }
        label {
          font-size: 16px;
          font-weight: 700;
          line-height: 20.42px;
        }
        input,
        textarea,
        select {
          padding: 12px 16px 12px 16px;
          border-radius: 4px;
          border: 1px solid #d0d5dd;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.42px;
          height: 48px;
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        text-align: center;
      }
    }
  }
}
.order-detail {
  margin: 24px 0px;
  background-color: #fff;
  border: 1px solid #eaecf0;

  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px 16px 16px !important;
  color: #000;
  .profile_img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
  .fw-500 {
    font-weight: 500;
    font-size: 1.1rem;
  }
  .order-id {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.42px;
  }
  .deliver-date {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.42px;
  }
  .address {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.42px;
    }
    &:nth-child(2) {
      text-transform: uppercase;
    }
  }
  .product-details {
    display: flex;
    gap: 0.8rem;
    .name {
      font-size: 16px;
      font-weight: 500;
      line-height: 20.42px;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.31px;
      }
    }
    .quantity {
      font-size: 16px;
      font-weight: 500;
      line-height: 20.42px;
    }
    .price {
      font-size: 24px;
      font-weight: 500;
      line-height: 30.62px;
    }
    img {
      height: 9rem;
      width: 8rem;
      object-fit: cover;
    }
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
  .order-summary {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .sub-total {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.31px;
    }
    .total {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
      .h5 {
        display: flex;
        flex-direction: column;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 15.31px;
        }
      }
      .amount {
        text-align: right;
      }
    }
  }
}
