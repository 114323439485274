.earning-payout-container {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .card-container {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
    }
  }
  .statistics {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .statistics-card {
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #eaecf0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.86px;
      }
      .value {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
    }
  }
  .growth-percentage {
    .current-earning {
      font-size: 36px;
      font-weight: 500;
      line-height: 50.4px;
      color: #181d25;
      margin-bottom: 6px;

      display: flex;
      align-items: flex-end;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        font-size: 13px;
        font-weight: 500;
        line-height: 16.59px;
        color: #03a01c;
        background: #cefdde;
        border-radius: 6px;
        margin-left: 6px;
      }
    }
    .date {
      margin-bottom: 18px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: #606e80;
    }
    .filter {
      background: #f6f7f9;
      border-radius: 8px;
      width: fit-content;
      padding: 2px;
      button {
        color: #606e80;
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        background: transparent;
        border: none;
        padding: 6px 16px;
      }

      .active {
        border: 1px solid #e1e5ea;
        background: #ffffff;
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        color: #181d25;
        border-radius: 6px;
      }
    }
  }
  .list {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .table-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-width: calc(100vw - 390px);
      overflow-x: scroll;
      .table-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
      table {
        thead {
          width: 100%;
          th {
            width: 100%;
            height: 100%;
            padding: 6px 12px;
            border: 1px solid #eaecf0;
            text-align: center;
          }
        }
        tbody {
          tr {
            td {
              .data-wrapper{
                display: flex;
                flex-direction: column;
                gap: 10px;
              }
              border: 1px solid #eaecf0;
              padding: 12px;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.86px;
              text-align: center;
              .shipping-type {
                width: max-content;
              }

              select {
                border: 1px solid #eaecf0;
                font-size: 12px;
                font-weight: 400;
                line-height: 15.31px;
                border-radius: 4px;
              }
              .product-detail-wrapper {
                display: flex;
                gap: 10px;
                img {
                  width: 60px;
                  height: 60px;
                }
                .product-detail {
                  display: flex;
                  flex-direction: column;
                  gap: 6px;
                  justify-content: center;
                  align-items: flex-start;
                  .name {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17.86px;
                  }
                  .category {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15.31px;
                  }
                }
              }
              .green-text {
                color: #069952;
              }
              .red-text {
                color: #e31e24;
              }
              .action-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: #2571fa;
                width: max-content;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .earning-payout-container{
    .list{
      .table-container{
        max-width: calc(100vw - 90px);
      }
    }
  }
}
