.circle-component {
  height: 100%;
  width: 100%;
  overflow: auto;
  .circle-container {
    height: calc(100vh - 90px);

    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    .circle-wrapper {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaded2;
      position: absolute;
      top: 50%;
      left: -20vw;
      transform: translate(-50%, -50%);
      @media (max-width:500px) {
        left: -40vw;
      }
      .circle {
        width: 100vw;
        height: 100vw;
        border-radius: 50vw;
        border: 2px solid #c7c7c7;
        overflow: hidden;
        transition: transform 0.8s ease;
        background: transparent;
        @media (max-width:500px) {
          width: 142vw;
          height: 142vw;
        border-radius: 562vw;

        }
      }

      .dot {
        width: 12px;
        height: 12px;
        background-color: #333;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        // ranslate(-6%, 4%): ;
        text-align: center;
        position: sticky;
      }
    }
    section {
      .container {
        display: flex;
        flex-direction: column;
        width: 45vw;
        max-width: 600px;
        color: black;
        justify-content: center;
        gap: 24px;
        h1 {
          font-family: Space Grotesk;
          font-size: 64px;
          font-weight: 700;
          line-height: 81.66px;
          text-align: left;
        }
        .contant {
          font-family: Space Grotesk;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
        }
        button {
          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 700;
          line-height: 20.42px;
          text-align: left;
          width: fit-content;
        }
        @media (max-width:1000px) {
        width: 55vw;

          h1{
            font-size: 54px;
          }
          
        }
        @media (max-width:700px) {
        width: 65vw;

          h1{
            font-size: 54px;
          }
          
        }
        @media (max-width:500px) {
        width: 85vw;
        max-width: 100%;


          h1{
            font-size: 34px;
          line-height: 31.66px;

          }
          
        }
      }
    }
  }
}
