.item {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  cursor: pointer;
  img {
    width: 2.5rem;
    height: 4rem;
    object-fit: cover;
  }
  span {
    &:first-of-type {
      font-size: 1.2rem;
      font-weight: 600;
    }
    &:last-of-type {
      font-weight: 400;
      color: #666666;
      @media (max-width: 450px) {
       display: none;
      }
    }
  }
}
