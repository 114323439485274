@import "../../App.scss";

.home-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: calc(100vh - 90px);

  section {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    scroll-snap-align: start;
  }
  .one {
    background: #eaded2;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
    .left-column {
      width: 33%;
      position: relative;

      .vector-fill {
        img {
          position: absolute;
          top: 50%;
          left: -7vw;
          transform: translateY(-60%);
          width: 30vw;
          z-index: 11;
        }
      }
      .content {
        position: absolute;
        top: -10vh;
        right: 0;
        transform: translateY(-50%);
        font-size: 4vw;
        z-index: 12;
      }
      .vector {
        img {
          position: absolute;
          top: 50%;
          left: 18vw;
          transform: translateY(-60%);
          width: 18vw;
        }
      }
    }
    .middle-column {
      width: 33%;
      position: relative;
      z-index: 13;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2vw;
      .logo {
        display: flex;
        justify-content: center;
        img {
          width: 13vw;
        }
      }
      .slick-slider {
        .slick-arrow {
          display: none !important;
        }
        img {
          width: 22vw;
          margin: auto;
        }
      }
      .scroll-down {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: #1f1f1f;
        font-size: 12px;
        cursor: pointer;
        .scroll-down-inner-wrapper {
          svg {
            fill: #1f1f1f;
          }
        }
      }
    }
    .right-column {
      width: 33%;
      position: relative;

      .vector-fill {
        img {
          position: absolute;
          top: 50%;
          right: -3vw;
          transform: translateY(-45%);
          width: 16vw;
        }
      }
      .content {
        position: absolute;
        top: -10vh;
        left: 0;
        transform: translateY(-50%);
        font-size: 4vw;
        z-index: 12;
      }
      .vector {
        img {
          position: absolute;
          top: 50%;
          right: 11vw;
          transform: translateY(-45%);
          width: 26vw;
          z-index: 0;
        }
      }
    }
    @media (max-width: 1000px) {
      .middle-column {
        .logo {
          img {
            width: 18vw;
          }
        }
        .slick-slider {
          img {
            width: 29vw;
            margin: auto;
          }
        }
      }
    }
    @media (max-width: 800px) {
      .left-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            left: -12vw;
            transform: translateY(-60%);
            width: 38vw;
            z-index: 11;
          }
        }
        .content {
          position: absolute;
          top: -10vh;
          right: -9vw;
          transform: translateY(-50%);
          font-size: 6vw;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            left: 18vw;
            transform: translateY(-60%);
            width: 22vw;
          }
        }
      }
      .middle-column {
        width: 50%;
        position: relative;
        z-index: 13;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2vw;
        .logo {
          display: flex;
          justify-content: center;
          img {
            width: 20vw;
          }
        }
        .slick-slider {
          .slick-arrow {
            display: none !important;
          }
          img {
            width: 33vw;
            margin: auto;
          }
        }
        .scroll-down {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          color: #1f1f1f;
          font-size: 12px;
          cursor: pointer;
          .scroll-down-inner-wrapper {
            svg {
              fill: #1f1f1f;
            }
          }
        }
      }
      .right-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            right: -3vw;
            transform: translateY(-45%);
            width: 24vw;
          }
        }
        .content {
          position: absolute;
          top: 7vh;
          left: -8vw;
          transform: translateY(-60%);
          font-size: 6vw;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            right: 11vw;
            transform: translateY(-45%);
            width: 28vw;
            z-index: 0;
          }
        }
      }
    }
    @media (max-width: 600px) {
      .left-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            left: -21vw;
            transform: translateY(-60%);
            width: 38vw;
            z-index: 11;
          }
        }
        .content {
          position: absolute;
          top: -10vh;
          right: -2vw;
          transform: translateY(-50%);
          font-size: 7vw;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            left: 9vw;
            transform: translateY(-60%);
            width: 22vw;
          }
        }
      }
      .middle-column {
        width: 50%;
        position: relative;
        z-index: 13;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2vw;
        .logo {
          display: flex;
          justify-content: center;
          img {
            width: 30vw;
          }
        }
        .slick-slider {
          .slick-arrow {
            display: none !important;
          }
          img {
            width: 43vw;
            margin: auto;
          }
        }
        .scroll-down {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          color: #1f1f1f;
          font-size: 12px;
          cursor: pointer;
          .scroll-down-inner-wrapper {
            svg {
              fill: #1f1f1f;
            }
          }
        }
      }
      .right-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            right: -9vw;
            transform: translateY(-45%);
            width: 25vw;
          }
        }
        .content {
          position: absolute;
          top: 7vh;
          left: -7vw;
          transform: translateY(-60%);
          font-size: 7vw;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            right: 9vw;
            transform: translateY(-45%);
            width: 33vw;
            z-index: 0;
          }
        }
      }
    }
    @media (max-width: 400px) {
      .left-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            left: -32vw;
            transform: translateY(-60%);
            width: 50vw;
            z-index: 11;
          }
        }
        .content {
          position: absolute;
          top: -10vh;
          right: -11vw;
          transform: translateY(-50%);
          font-size: 32px;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            left: 9vw;
            transform: translateY(-60%);
            width: 28vw;
          }
        }
      }
      .middle-column {
        width: 60%;
        position: relative;
        z-index: 13;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2vw;
        .logo {
          display: flex;
          justify-content: center;
          img {
            width: 35vw;
          }
        }
        .slick-slider {
          .slick-arrow {
            display: none !important;
          }
          img {
            width: 55vw;
            margin: auto;
          }
        }
        .scroll-down {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          color: #1f1f1f;
          font-size: 12px;
          cursor: pointer;
          .scroll-down-inner-wrapper {
            svg {
              fill: #1f1f1f;
            }
          }
        }
      }
      .right-column {
        width: 33%;
        position: relative;

        .vector-fill {
          img {
            position: absolute;
            top: 50%;
            right: -18vw;
            transform: translateY(-45%);
            width: 32vw;
          }
        }
        .content {
          position: absolute;
          top: 7vh;
          left: -10vw;
          transform: translateY(-60%);
          font-size: 320z;
          z-index: 12;
        }
        .vector {
          img {
            position: absolute;
            top: 50%;
            right: -4vw;
            transform: translateY(-45%);
            width: 45vw;
            z-index: 0;
          }
        }
      }
    }
  }
  .two {
    padding-top: 0px;
    background: #eaded2;
    transition: opacity 0.5s;
    animation: ease 1s;
    position: relative;
    .slick-slider {
      width: 260px;
      z-index: 11;
      @media (max-width: 800px) {
        width: 208px;
      }
      @media (max-width: 700px) {
        width: 190px;
      }
      @media (max-width: 600px) {
        width: 170px;
      }
      @media (max-width: 500px) {
        width: 150px;
        left: -50px;
      }
      .slick-arrow {
        display: none !important;
      }
      img {
        width: 100%;
        margin: auto;
      }
    }
  }
  .tree {
    position: relative;
    z-index: 1;
    background: #eaded2;
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      padding: 0px 40px;
      height: 365px;
      .left-column {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
          //styleName: 32;
          font-family: Space Grotesk;
          font-size: 32px;
          font-weight: 500;
          line-height: 40.83px;
          text-align: left;
          color: #1f1f1f;
        }
        .button-wrapper {
          button {
            font-family: Space Grotesk;
            font-size: 16px;
            font-weight: 700;
            line-height: 20.42px;
            text-align: left;
          }
        }
      }
      .right-column {
        width: 70%;
        padding-bottom: 20px;
        height: fit-content;

        overflow: auto;
        .slider-wrapper {
          display: flex;
          .home-slider-item {
            min-width: 230px;
            .slider-img {
              width: 100%;
            }
          }
        }
      }
      @media (max-width: 1000px) {
        .left-column {
          width: 35%;
        }
        .right-column {
          width: 65%;
        }
      }
      @media (max-width: 855px) {
        gap: 20px;
        // display: flex;
        flex-direction: column;
        height: fit-content;
        .left-column {
          width: 100%;
        }
        .right-column {
          width: 100%;
        }
      }
      @media (max-width: 855px) {
        padding: 0px 20px;
      }
    }
    .side-img {
      position: absolute;
      left: -40px;
      bottom: -40px;
      img {
        width: 20vw;
        transform: rotateY(180deg);
        @media (max-width: 1000px) {
          width: 25vw;
        }
        @media (max-width: 800px) {
          width: 20vw;
        }
        @media (max-width: 600px) {
          width: 30vw;
          right: -40px;
        }
      }
      // angle: -180 deg;
    }
  }
  .four {
    position: relative;
    z-index: 1;
    background: #eaded2;
    width: 100%;
    height: 100vh;
    .row {
      width: 100%;
      display: flex;
      padding: 0px 40px;
      height: 365px;
      .left-column {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
          font-family: Space Grotesk;
          font-size: 24px;
          font-weight: 500;
          line-height: 30.62px;
          text-align: left;
          color: #1f1f1f;
        }
        .contatnt {
          color: #000000;

          //styleName: 32;
          font-family: Space Grotesk;
          font-size: 32px;
          font-weight: 500;
          line-height: 40.83px;
          text-align: left;
        }
      }
      .right-column {
        width: 50%;
        padding-bottom: 20px;
        height: 100%;

        overflow: auto;
        .faq {
          display: flex;
          flex-direction: column;
        }
      }
      @media (max-width: 1000px) {
        height: fit-content;
        flex-direction: column;
        gap: 40px;
        .left-column {
          width: 100%;
          .title {
            font-size: 18px;
          }
          .contatnt {
            font-size: 24px;
          }
        }
        .right-column {
          width: 100%;
        }
      }
      @media (max-width: 600px) {
        padding: 0px 20px;
        .left-column {
          width: 100%;
        }
      }
    }
    .side-img {
      position: absolute;
      left: -40px;
      bottom: -40px;
      img {
        transform: rotateY(180deg);
        width: 20vw;
        @media (max-width: 1000px) {
          width: 25vw;
        }
        @media (max-width: 600px) {
          right: -40px;
          left: unset;
          width: 30vw;
        }
      }
      // angle: -180 deg;
    }
  }
}

@media (max-width: 600px) {
  .home-container {
    section {
      padding-top: 64px;
    }
  }
}
