.notification-ticket {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .notification-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .notification-card {
      display: flex;
      flex-direction: row;
      gap: 6px;
      // border: 1px solid #eaecf0;
      // padding: 12px;
      border-radius: 6px;
      .content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 20.42px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 17.86px;
        }
      }
    }
  }
}
