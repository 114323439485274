.overview-container {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .statistics {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .statistics-card {
      background: #ffffff;
      border-radius: 12px;
      border: 1px solid #eaecf0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.86px;
      }
      .value {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
    }
  }
  .store-url {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .url-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      border: 1px solid #eaecf0;

      font-size: 14px;
      font-weight: 400;
      line-height: 17.86px;
      border-radius: 6px;
      width: fit-content;
      .url {
        padding: 12px;
      }
      .copy-btn {
        border-radius: 6px;
        padding: 12px;
        background: #fbf8f6;
        cursor: pointer;
      }
    }
  }
  .card-container {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.52px;
    }
  }
  .order-cancellation-request {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      display: flex;
      justify-content: space-between;
      .view-all {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.42px;
        cursor: pointer;
      }
    }
    .request-list {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      .request-wrapper {
        border: 1px solid #eaecf0;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.86px;
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.42px;
        }
        .view-detail {
          color: #2571fa;
          cursor: pointer;
        }
        .action-btn {
          display: flex;
          gap: 12px;
        }
      }
    }
  }
  .list {
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: calc(100vw - 340px);

    .table-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .table-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
      overflow-x: scroll;
      table {
        thead {
          width: 100%;
          th {
            width: 100%;
            height: 100%;
            padding: 6px 12px;
            border: 1px solid #eaecf0;
            text-align: center;
          }
        }
        tbody {
          tr {
            td {
              .data-wrapper{
                display: flex;
                flex-direction: column;
                gap: 10px;
              }
              border: 1px solid #eaecf0;
              padding: 12px;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.86px;
              text-align: center;
              .shipping-type {
                width: max-content;
              }

              select {
                border: 1px solid #eaecf0;
                font-size: 12px;
                font-weight: 400;
                line-height: 15.31px;
                border-radius: 4px;
              }
              .product-detail-wrapper {
                display: flex;
                gap: 10px;
                img {
                  width: 60px;
                  height: 60px;
                }
                .product-detail {
                  display: flex;
                  flex-direction: column;
                  gap: 6px;
                  justify-content: center;
                  align-items: flex-start;
                  .name {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17.86px;
                  }
                  .category {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15.31px;
                  }
                }
              }
              .green-text {
                color: #069952;
              }
              .red-text {
                color: #e31e24;
              }
              .action-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: #2571fa;
                width: max-content;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 800px) {
  .overview-container{
    .list{
      max-width: calc(100vw - 80px);
    }
  }
}

@media (max-width: 500px) {
  .overview-container{
    .list{
      max-width: calc(100vw - 40px);
    }
  }
}