.order-cancellation-request {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    .view-all {
      font-size: 16px;
      font-weight: 400;
      line-height: 20.42px;
      cursor: pointer;
    }
  }
  .request-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .request-wrapper {
      border: 1px solid #eaecf0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17.86px;
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
      }
      .view-detail {
        color: #2571fa;
        cursor: pointer;
      }
      .action-btn {
        display: flex;
        gap: 12px;
      }
    }
  }
}
