@import "../../App.scss";

.main {
  padding: 1.5rem;
  font-size: 1.1rem;
  .routes {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    .underline-none,
    .icon {
      color: #626262;
    }
  }
  .product-container {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    height: fit-content;
    @media (max-width: 1024px) {
      flex-direction: column;
      gap:1rem;
    }
    .images-container {
      display: flex;
      flex-direction: column;
      width: 45vw;
      // width: 100%;
      @media (max-width: 1024px) {
        grid-column: 0;
        width: 100%;
      }
      .image-main {
        position: relative;
        background-color: #f5f5f5;
        img {
          width: 100%;
          height: 70vh;
          object-fit: cover;
          @media (max-width: 1024px) {
            width: 100%;
            height: 60vh;
            object-fit: contain;
          }
          @media (max-width: 500px) {
            object-fit: cover;
          }
        }
        .icon-bg {
          width: 2.5rem;
          height: 2.5rem;
          background-color: $color-secondary;
          border-radius: 50%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          top: 50%;
          .icon {
            color: $color-primary;
            font-size: 1.2rem;
          }
          &:last-of-type {
            transform: translateY(-50%);
            right: 2rem;
          }
          &:first-of-type {
            transform: translateY(-50%);
            left: 2rem;
          }
        }
      }
      .thumbnails {
        display: flex;
        overflow: scroll;
        justify-content: flex-start;
        gap: 1rem;
        padding: 1rem 0;
        width: 100%;
        @media (max-width: 1024px) {
          justify-content: center;
        }
        // .react-slideshow-container{
        //   width: 53vw;
        //   .react-slideshow-wrapper{
        //     .images-wrap{
        //       div{
        //         // height:9.08rem !important;
        //         width:7rem !important;
        //         // width:127.2px !important;
        //         img{
        //           width:6.5rem !important;
        //         }
        //       }
        //     }
        //   }
        // }
        &::-webkit-scrollbar {
          height: 5px;
          scroll-behavior: smooth;
          @media (max-width: 600px) {
            display: none;
          }
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 1px;
          background-color: $color-primary;
        }
        img {
          width: 5rem;
          height: 7rem;
          object-fit: cover;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 8rem;
          }
        }
        .active {
          border-bottom: 2px solid $color-primary;
        }
      }
      .options {
        padding: 1rem 0;
        display: flex;
        gap: 1rem;
        align-self: center;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 600px) {
          width: 70%;
          margin: 0 auto;
          div:nth-of-type(2) {
            order: 3;
          }
        }
        div {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;
          .icon {
            font-size: 1.6rem;
          }
          span {
            font-size: 1.3rem;
          }
        }
      }
    }
    .product-details {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 1.1rem;
      width: 45%;
      @media (max-width: 1024px) {
        width: 100%;
        grid-column: 1 / span 2;
      }
      
      .rating-icons {
        // margin-bottom: 2rem;
      }
      h1 {
        width: 80%;
        font-size: 2.5rem;
        font-weight: 500;
      }
      p {
        font-size: 1.1rem;
      }
      h2 {
        font-size: 2.4rem;
        font-weight: 400;
      }
      .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-bottom: 0.5rem;
        @media (max-width: 600px) {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      .delivery-input {
        display: flex;
        flex-direction: column;
        font-size: 1.1rem;
        .input-box {
          width: 70%;
          display: flex;
          border-radius: 0.3rem;
          padding: 0.5rem;
          border: 1px solid #c2c2c2;
          @media (max-width: 600px) {
            width: 90%;
          }
          input {
            width: 100%;
            outline: none;
            border: none;
          }
        }
        .truck {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          .icon {
            font-size: 1.2rem;
          }
          span {
            font-size: 1.1rem;
          }
        }
      }
      .accordion-box {
        border-block: 1px solid #6b6f80 !important;
        #accordion-description {
          border-left: none !important;
        }
      }
    }
  }
}
