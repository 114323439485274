@import "../../App.scss";

.membership-card {
  box-shadow: 0px 16px 12px 0px #10182814;
  border: 1px solid #d7dbe2;
  width: min(350px, 100%);
  border-radius: 20px;
  position: relative;
  min-height: 570px;
  .header {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    border-bottom: 1px solid #eaecf0;
    .amount {
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 61.25px;
      text-align: center;
    }
    .plan-name {
      font-size: 20px;
      font-weight: 600;
      line-height: 25.52px;
      text-align: center;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 410px;
    .features {
      padding: 32px;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        li {
          display: flex;
          gap: 12px;
          align-items: center;
          color: #475467;
          font-family: Space Grotesk;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.42px;
          text-align: left;
        }
      }
    }
    .button-wrapper {
      padding: 32px;
      button {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
        border-radius: 6px;
      }
    }
  }
  .popular-tag {
    // height: 19px;
    border-radius: 6px;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: #eaded2;
    color: #dd5313;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.31px;
    text-align: center;
    padding: 2px 6px;
  }
  @media (max-width: 600px) {
    .header {
      .amount {
        font-size: 36px;
      }
      .plan-name {
        font-size: 20px;
      }
    }
    .content {
      .features {
        ul {
          li {
            font-size: 16px;
          }
        }
      }
      .button-wrapper {
        button {
          font-size: 16px;
        }
      }
    }
  }
}
