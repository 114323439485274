@import "../../App.scss";

.image {
  position: relative;
  background-size: 85%;
  height: 100%;
  background-position: bottom;
  font-size: 1.1rem;
  @media (max-width: 960px) {
    background-size: cover;
  }
  @media (max-width: 600px) {
    display: none;
  }
  .review-box {
    background-color: $color-secondary;
    position: absolute;
    bottom: 4rem;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 600;
    .review {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1rem;
      .rating {
        display: flex;
        gap: 0.2rem;
        color: #fed600;
      }
    }
    .artist {
      font-size: 0.9rem;
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }
  }
}
