.seller-register {
  min-height: calc(100vh - 90px);
  // position: relative;
  padding: 24px;
  background: #fbf8f6;
  display: flex;
  justify-content: center;
  align-items: center;
  .seller-form-wrapper {
    max-width: 600px;
    padding: 24px;
    // margin: 24px;
    width: 100%;
    background: white;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    // margin-top: 90px;
    // margin-bottom: 90px;
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 30.62px;
    }
    form {
      .input-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .upload-logo {
          cursor: pointer;
          .preview-image {
            img {
              width: 117px;
              height: 96px;
            }
          }
          .upload-file-button {
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.31px;
            padding: 30px;
            gap: 0px;
            border-radius: 6px;
            border: 1px solid #eaecf0;
            background: #ffffff;
            width: fit-content;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            justify-content: center;
          }
        }
        .input-wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .preview-image {
            img {
              width: 100px;
              height: 100px;
            }
            cursor: pointer;
          }
          .upload-file-button {
            cursor: pointer;
            background: #f9f9f9;
            padding: 12px 16px 12px 16px;
            border-radius: 4px;
            width: fit-content;
          }
          label {
            font-size: 16px;
            font-weight: 700;
            line-height: 20.42px;
          }
          input,
          textarea,
          select {
            padding: 12px 16px 12px 16px;
            border-radius: 4px;
            border: 1px solid #d0d5dd;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.42px;
          }
          .input-btn-container{
            display: flex;
            gap: 10px;

            input{
              width: 100%;
            }
            button{
              text-wrap: nowrap;
            }
          }
        }
        .gender {
          .gender-input-wrapper {
            display: flex;
            gap: 4px;
          }
          display: flex;
          gap: 8px;

          .input-wrapper {
            gap: 8px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            label {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: -0.25px;
              color: #838799;
            }
            input {
              width: 15px;
              height: 15px;
              &:checked {
                accent-color: black;
              }
            }
          }
        }
        .address {
          display: flex;
          gap: 12px;
          .input-wrapper {
            // flex: 1;
            width: 32%;
            select {
              font-weight: 700;
              color: #838799;
            }
          }
          @media (max-width: 500px) {
            flex-direction: column;
            .input-wrapper {
              width: 100%;
            }
          }
        }
        .email-notification {
          .input-wrapper {
            gap: 8px;

            display: flex;
            flex-direction: row;
            align-items: center;
            label {
              font-size: 16px;
              font-weight: 400;
              line-height: 20.42px;
            }
            input {
              width: 15px;
              height: 15px;
              &:checked {
                accent-color: black;
              }
            }
          }
        }
        .product-type-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          label {
            font-size: 16px;
            font-weight: 400;
            line-height: 20.42px;
          }
          .product-type {
            display: flex;
            gap: 12px;
            div {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
