.slider-item {
  width: 100%;
  padding: 0 1rem !important;
  height: 43rem !important;
  .img{
    width: 20px;
  }
  .slider-img {
    object-fit: cover;
    height: 28rem !important;
    width: 100%;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    height: 38rem !important;
    .slider-img {
      height: 22rem !important;
    }
  }
  .details {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    width: inherit;
    padding: 0.5rem 0 1rem 0;
    .flex-between {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      span {
        font-weight: 500;
      }
    }
    .flex-column {
      display: flex;
      flex-direction: column;
      font-size: 1.2rem;
      gap: 0.3rem;
      .catogory-title {
        font-family: Space Grotesk;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.31px;
        text-align: left;
      }
      .price {
        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.42px;
        text-align: left;
      }
      .emi {
        font-family: Space Grotesk;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.31px;
        text-align: left;
      }
      .bottom-btn {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        button {
          font-family: Space Grotesk;
          font-size: 12px;
          font-weight: 500;
          line-height: 15.31px;
          text-align: left;
          padding: 8px 12px 8px 12px;
          gap: 8px;
          border: 1px 0px 0px 0px;
          opacity: 0px;
          text-align: center;
        }
        .flex-end {
          align-self: flex-end;
          display: flex;
          align-items: center;
          margin: auto;
          gap: 0.2rem;
          color: #467fe4;
          font-family: Space Grotesk;
          font-size: 12px;
          font-weight: 700;
          line-height: 15.31px;
          text-align: left;

          span {
            text-decoration: underline;
          }
          img {
            width: 12px;
          }
        }
      }
    }
  }
}
