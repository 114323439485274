@import "../../../../App.scss";

.accordion-faq-box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  .question {
    padding: 0.7rem;
    background-color: $color-secondary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 1px solid #333333;
    background: transparent;
    //styleName: 16;
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.42px;
    text-align: left;
    color: #333333;
    cursor: pointer;

    .icon {
      color: #333333;
    }
  }
  #accordion-description {
    padding: 0.7rem;
    background-color: $color-secondary;
    // border: 1px solid #eaecf0;
    border-top: none;

    padding-left: 0.5rem;
    border-radius: 0px 0px 6px 6px;
    background: transparent;
    color: #333333;
    //styleName: 12;
    font-family: Space Grotesk;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.31px;
    text-align: left;
  }
}
