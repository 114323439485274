.header-offcanvas {
  display: flex;
  align-items: center;
  // background-color: #111;
  color: black;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  font-family: Space Grotesk;
  font-weight: 500;
  line-height: 25.52px;

  .icon {
    cursor: pointer;
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
  }
}
.review-content {
  .input-box {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    .profile {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .icon {
        font-size: 1.8rem;
      }
    }
    .rating {
      display: flex;
      flex-direction: column;
    }
    textarea{
      background: #F9F9F9;
      border: none;
      border-radius: 4px;
      padding: 12px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}

.modal-body {
  padding: 0px 16px 16px 16px;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  color: #000;
  .profile_img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
}
