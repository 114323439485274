@import "./../../../../../App.scss";
.membership {
  .membership-plan {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 30.62px;
      text-align: center;
    }
    .membership-plan-detail {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 24px;
    }
    @media (max-width: 600px) {
      padding: 20px;
      .title {
        font-size: 20px;
      }
      .membership-plan-detail {
        gap: 30px;
      }
    }
  }
  .schedule-call {
    padding: 96px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 700px;
    margin: auto;
    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.02em;
      text-align: center;
    }
    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: #667085;
    }
    .input-conatiner {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 320px;
      label {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.42px;
        text-align: left;
      }
      input {
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        border-radius: 6px;
      }
    }
    .button-wrapper {
      button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
        text-align: left;
      }
    }
    @media (max-width: 600px) {
      padding: 50px 20px;

      .title {
        font-size: 30px;
      }
      .content {
        font-size: 16px;
      }
    }
  }
}
