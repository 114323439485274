.artwork-category {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .header {
    padding: 0px !important;
    align-items: center;
  }
  table {
    thead {
      .category {
        text-align: left;
      }
      .action {
        max-width: 100px;
      }
      th {
        border: 1px solid #eaecf0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.86px;
        color: black;
        padding: 6px 12px;
        text-align: center;
      }
    }
    tbody {
      tr {
        .category {
          text-align: left;
        }
        .action {
            max-width: 100px;
          }
        td {
          border: 1px solid #eaecf0;
          padding: 6px 12px;
          text-align: center;

          .action-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            span {
              cursor: pointer;
              color: #2571fa;
            }
          }
        }
      }
    }
  }
}
