@import "../../App.scss";

.rental-container {
  padding: 1.5rem;
  font-size: 1.1rem;
  .routes {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    .underline-none,
    .icon {
      color: #626262;
    }
  }
  .rental-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 3rem;
    padding: 3rem 0;
    @media (max-width: 835px) {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    h1 {
      font-size: 2rem;
      font-weight: 500;
    }
    .rental-form-side {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        border: 1px solid #c2c2c270;
        padding: 1rem;
        .inputs {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          @media (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
          }
          .input-box {
            display: flex;
            flex-direction: column;
            &:last-of-type {
              grid-column: 1 / span 2;
              @media (max-width: 600px) {
                grid-column: auto;
              }
            }
            input {
              outline: none;
              border: 1px solid #c2c2c2;
              padding: 0.5rem;
              border-radius: 0.2rem;
            }
          }
        }
        .btn-primary {
          width: fit-content;
          align-self: flex-end;
          padding-inline: 2rem !important;
        }
      }
    }
    .rental-delivery-info {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border: 1px solid #c2c2c270;
      border-radius: 0.5rem;
      font-size: 1.2rem;
      h1 {
        font-size: 1.6rem;
        font-weight: 500;
      }
      span {
        color: #467fe4;
        cursor: pointer;
      }
    }
    .rental-summary {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h1 {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        .icon {
          cursor: pointer;
        }
      }
      .order-details {
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #c2c2c270;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        .item {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          .details {
            display: flex;
            gap: 0.5rem;
            height: 100%;
            @media (max-width: 600px) {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            img {
              width: 9rem;
              height: 10rem;
              object-fit: cover;
              @media (max-width: 600px) {
                width: 100%;
                height: 20rem;
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 1rem;
                @media (max-width: 600px) {
                  grid-template-columns: repeat(1, 1fr);
                  justify-items: flex-end;
                }
                div {
                  span {
                    font-size: 1.3rem;
                  }
                  .amt {
                    font-weight: 600;
                  }
                  &:first-of-type {
                    grid-column: 1 / span 2;
                    @media (max-width: 600px) {
                      grid-column: auto;
                    }
                  }
                  select {
                    padding: 0.4rem 1rem;
                    background: #f7f7f7;
                    border: none;
                    outline: none;
                    option:checked {
                      background-color: #c2c2c2;
                    }
                  }
                }
              }
              .remove {
                color: #467fe4;
                align-self: flex-end;
                cursor: pointer;
              }
            }
          }
          .art-protection {
            padding: 1rem;
            border-radius: 0.8rem;
            border: 1px solid #c2c2c270;
            display: flex;
            flex-direction: column;
            h3 {
              font-size: 1.5rem;
            }
            h4 {
              font-size: 1.3rem;
            }
            p {
              span {
                text-decoration: underline;
                color: #467fe4;
                cursor: pointer;
              }
            }
            .remove {
              color: #467fe4;
              align-self: flex-end;
              cursor: pointer;
            }
          }
        }
        .flex-between {
          h4 {
            font-size: 1.4rem;
          }
        }
        .link {
          color: #467fe4;
          text-decoration: underline;
          justify-self: flex-end;
        }
      }
    }
  }
}

.rental-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  h4 {
    font-size: 1.4rem;
  }
  span {
    font-size: 1.3rem;
  }
}
