.progress-bar-container {
    overflow: auto;
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        position: relative;
        justify-content: space-between;
        // min-width: 950px;
        li {
            list-style: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.42px;
            display: flex;
            gap: 12px;
            z-index: 11;
            background: white;
            padding: 0px 4px;
        }
        .line {
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            background: #98a2b3;

            z-index: 0;
        }
    }
}

@media (max-width: 600px) {
    .progress-bar-container {
        ul {
          flex-direction: column;
        }
    }
}
