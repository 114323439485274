@import "../../App.scss";

.popular-artwork {
  background-color: $color-secondary;
  padding: 2rem 0;
  h2 {
    font-size: 2.3rem;
    font-weight: 500;
    margin: 2rem 0 3rem 1rem;
  }
}
