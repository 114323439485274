.faq {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 600px) {
    padding: 3rem 2rem;
  }
  .row {
    width: 100%;
    display: flex;
    
    .right-column {
      width: 100%;
      padding-bottom: 20px;
      height: 100%;

      overflow: auto;
      .faq {
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: 1000px) {
      height: fit-content;
      flex-direction: column;
      gap: 40px;
      .left-column {
        width: 100%;
        .title {
          font-size: 18px;
        }
        .contatnt {
          font-size: 24px;
        }
      }
      .right-column {
        width: 100%;
      }
    }
    @media (max-width: 600px) {
      // padding: 0px 20px;
      .left-column {
        width: 100%;
      }
    }
  }
  .side-img {
    position: absolute;
    left: -40px;
    bottom: -40px;
    img {
      transform: rotateY(180deg);
      width: 20vw;
      @media (max-width: 1000px) {
        width: 25vw;
      }
      @media (max-width: 600px) {
        right: -40px;
        left: unset;
        width: 30vw;
      }
    }
    // angle: -180 deg;
  }
}
