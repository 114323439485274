.address-div {
  margin: 1rem 0;
  cursor: pointer;
  .address-side {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid #c2c2c270;
    border-radius: 0.5rem;
    @media (min-width: 521px) {
      width: 55vw;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.1rem;
      padding: 1rem;
      .inputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        @media (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .input-box {
          display: flex;
          flex-direction: column;
          &:last-of-type {
            grid-column: 1 / span 2;
            @media (max-width: 600px) {
              grid-column: auto;
            }
          }
          input {
            outline: none;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
        }
      }
      .buttons {
        display: flex;
        gap: 1rem;
        button {
          width: fit-content;
          align-self: flex-start;
          padding-inline: 2rem !important;
        }
      }
    }
    .delivery-info {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      font-size: 1.2rem;
      .details {
        display: flex;
        flex-direction: column;
      }
      h1 {
        font-size: 1.6rem;
        font-weight: 500;
      }
      .address-options {
        display: flex;
        gap: 1rem;
        width: fit-content;
        span:first-of-type {
          color: #467fe4;
          cursor: pointer;
        }
        span:last-of-type {
          color: #ad0101;
          cursor: pointer;
        }
      }
    }
    .selected {
      background-color: #f5bf7db0;
    }
  }
}
