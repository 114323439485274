@import "../../../App.scss";
.cancel-modal {
  .close {
    display: flex;
    justify-content: end;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.52px;
    text-align: center;
  }

  .buttons {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    button {
      padding-inline: 3rem !important;
    }
  }
}
