.checkout-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  text-align: center;
  .icon {
    font-size: 4rem;
  }
  img {
    width: 2.5rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.42px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.42px;
  }
  h5 {
    font-size: 1.3rem;
  }
  span {
    font-size: 1.3rem;
  }
}
