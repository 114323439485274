@import "../../App.scss";

.signup-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 80vh;
  align-items: center;
  font-size: 1.1rem;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 601px) {
    min-height: 90vh;
  }
  .signup-section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    .form-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 70%;
      @media (max-width: 450px) {
        width: 90%;
      }
      h1 {
        font-size: 2rem;
      }
      p {
        color: #8d8e89;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .input-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.3rem;
          label {
            font-weight: 600;
          }
          input {
            outline: none;
            width: 100%;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
        }
        .remember-me {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          p {
            color: $color-primary;
          }
          input {
            margin-bottom: 0.9rem;
            accent-color: $color-primary;
          }
        }
        .button {
          @include buttonStyles();
          background-color: $color-primary;
          color: $color-secondary;
          padding: 0.8rem;
          &:hover {
            background-color: #3f3f3f;
          }
        }
      }
      .google-signup-btn {
        @include buttonStyles();
        background-color: $color-secondary;
        color: $color-primary;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #3f3f3f;
          color: $color-secondary;
        }
        .google-icon {
          font-size: 1rem;
        }
      }
    }
    .to-login {
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 730px) {
        display: none;
      }
      span {
        display: flex;
        gap: 0.3rem;
        color: $color-primary;
        .link {
          font-weight: 700;
        }
      }
    }
  }
}
