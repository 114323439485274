.add-address-modal {
  .modal-dialog {
    max-width: 636px;
    .modal-content {
      margin: 24px;
      border-radius: 12px;
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.1rem;
        padding: 16px;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          svg{
            cursor: pointer;
          }
          h4 {
            font-size: 20px;
            margin: 0;
          }
        }
        .inputs {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .row {
            gap: 1rem;
            display: flex;
            flex-direction: row;
            // grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
          }
          .input-box {
            display: flex;
            flex-direction: column;
            width: fit-content;
            flex: 1;
            label {
              font-size: 16px;
              font-weight: 700;
              line-height: 20.42px;
            }

            &:last-of-type {
              // grid-column: 1 / span 2;
              @media (max-width: 600px) {
                // grid-column: auto;
              }
            }
            input {
              outline: none;
              border: 1px solid #d0d5dd;
              padding: 0.5rem;
              border-radius: 4px;
            }
          }
        }
        .buttons {
          display: flex;
          gap: 1rem;
          justify-content: flex-end;
          button {
            width: fit-content;
            align-self: flex-start;
            padding-inline: 2rem !important;
          }
        }
      }
    }
  }
}
