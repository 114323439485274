@import "../../../App.scss";

.dashboard-container {
  min-height: 100vh;
  background: #fbf8f6;
  display: flex;
  .right {
    padding: 0px 40px;
    flex: 1;
    .header {
      padding: 24px 0px;
      display: flex;
      justify-content: space-between;
      .title-wrapper {
        .title {
          font-size: 24px;
          font-weight: 500;
          line-height: 30.62px;
          margin-bottom: 12px;
        }
        .sub-title {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .notification {
        display: flex;
        gap: 12px;
        align-items: center;
        .icon {
          height: 48px;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid #eaecf0;
          cursor: pointer;
        }
        .profile {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // max-width: 9rem;
          .profile-img {
            width: 2.5rem;
            height: 2.5rem;
            object-fit: cover;
            border-radius: 50%;
          }
          svg{
            min-height: 20px;
            min-width: 20px;
            height: 20px;
            width: 20px;
          }
          @media (max-width: 1000px) {
            overflow: visible;
            span,
            .icon {
              display: none;
            }
          }
        }
        // @media (min-width: 1001px) {
        //   .sm-view-profile {
        //     display: none;
        //   }
        // }
      }
    }
  }
  .seller-profile-options{
    position: relative;
    
    .profile-options-bg{
      right: 0em;

      .profile-options{
        top: 5em;
      }
    }
  }
}

@media (max-width: 800px) {
  .dashboard-container{
    .left{
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .dashboard-container{
    .right{
      padding: 0 20px;
    }
  }
}