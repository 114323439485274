@import "../../App.scss";

.page-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 80vh;
  align-items: center;
  font-size: 1.1rem;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 601px) {
    min-height: 90vh;
  }
  .left-section {
    position: relative;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem;
    .form-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      h1 {
        font-size: 2rem;
      }
      p {
        color: #8d8e89;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .input-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.3rem;
          label {
            font-weight: 600;
          }
          input {
            outline: none;
            width: 100%;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.3rem;
          width: 14rem;
          label {
            font-weight: 600;
          }
          input {
            outline: none;
            padding: 0.5rem;
            border-radius: 0.2rem;
            background-color: #f9f9f9;
            border: none;
            &:focus {
              background-color: #f9f9f9;
            }
          }
          .date {
            width: 14rem;
          }
          .file-upload {
            padding: 0.7rem 1rem;
            background-color: #f9f9f9;
            span {
              cursor: pointer;
            }
          }
          .input {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            align-items: center;
            background-color: #f9f9f9;
            width: 100%;
            padding-right: 2rem;
            span {
              margin-left: -0.6rem;
              font-size: 1.6rem;
            }
          }
        }
        .button {
          @include buttonStyles();
          background-color: $color-primary;
          color: $color-secondary;
          padding: 0.5rem 3rem !important;
          align-self: flex-start;
          margin-top: 0.5rem;
          &:hover {
            background-color: #3f3f3f;
          }
        }
      }
    }
  }
}
