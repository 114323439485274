@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

$color-primary: #000;
$color-secondary: #fff;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Space Grotesk";
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  background: #fbf8f6;
}

html {
  // 1rem = 14px.
  font-size: 87.5%;
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 75%;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $color-primary;
}

@media only screen and (max-width: 1024px) {
  html {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
.slick-prev,
.slick-next {
  color: $color-primary;
  font-size: 2rem !important;
  border-radius: 50%;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 50;
  transition: 0.5s ease-out;
  position: absolute;
  top: 50%;
  &:hover {
    background-color: #fff;
    color: $color-primary;
  }
}
.react-slideshow-container {
  position: relative;
}

.slick-prev {
  margin-left: 4rem;
  left: 4rem;
}
.slick-next {
  right: 4rem;
  margin-right: 4rem;
}

.react-slideshow-container .nav {
  top: 12rem;
  @media (min-width: 600px) {
    top: 15rem;
  }
}

@media only screen and (max-width: 500px) {
  .slick-next,
  .slick-arrow,
  .slick-prev {
    display: none !important;
  }
}

@mixin buttonStyles() {
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  border: 1px solid $color-primary;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.underline-none {
  text-decoration: none;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.btn-primary {
  background-color: $color-primary;
  color: $color-secondary;
  border-radius: 6px;
  min-width: 95px;

  @include buttonStyles();
  &:hover {
    background-color: #3f3f3f;
    color: $color-secondary;
  }
}
.btn-secondary {
  background-color: transparent !important;
  color: $color-primary;
  border-radius: 6px;
  min-width: 95px;

  @include buttonStyles();
  &:hover {
    color: $color-secondary;
    background-color: #3f3f3f !important;
  }
}
.btn-gray {
  color: $color-secondary;
  background-color: #3f3f3f;
  @include buttonStyles();
}
.btn-delete {
  background-color: #830000b2;
  color: $color-secondary;
  @include buttonStyles();
  &:hover {
    background-color: #830000d2;
  }
}
.btn-cancel {
  @include buttonStyles();
  background-color: #8a0009fb;
  border: 1px solid #8a0009fb;
  color: #fff;
  &:hover {
    background-color: #b3000cfb;
  }
}
.btn-orange {
  @include buttonStyles();
  background-color:  #DD5313;
  ;
  border: 1px solid #DD5313;
  color: #fff;
  &:hover {
    background-color: #b1400c;
  }
}

.profile-options-bg {
  width: 100%;
  height: 100%;
  background-color: #3f3f3f31;
  position: fixed;
  z-index: 999;
  .profile-options {
    display: flex;
    padding: 1rem 2rem 1rem 1rem;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    right: 1rem;
    top: 0rem;
    background-color: $color-secondary;
    .option {
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      color: $color-primary;
      cursor: pointer;
      .icon {
        font-size: 1.3rem;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.icon-heart {
  font-size: 1.6rem;
  cursor: pointer;
}

.error {
  color: #ff0000;
  text-align: start;
}

.loading-card {
  width: 100%;
  height: 220px;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
    linear-gradient(#ddd, #ddd);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
  background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
  animation: loading 1.5s infinite;
}

.loading-cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .loading-card-item {
    width: 215px;
    // width: 100%;
    height: 220px;
    background: linear-gradient(0.25turn, transparent, #fff, transparent),
      linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
      linear-gradient(#ddd, #ddd);
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
    background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 215px 0, 0 0, 15px 150px, 15px 180px;
  }
}

.component-loader {
  width: 64px;
  height: 64px;
  position: relative;
  background-image: linear-gradient(#fff 16px, transparent 0),
    linear-gradient(#ff3d00 16px, transparent 0),
    linear-gradient(#ff3d00 16px, transparent 0),
    linear-gradient(#fff 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top, left bottom, right top, right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg);
  }
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #000;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #000;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

// loading spinner
.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.475);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #000;
    // box-shadow: 30px 0 0 #ff3d00;
  }
  50% {
    box-shadow: 0 0 0 #000;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #000;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}

.modal-body {
  border-radius: 4px !important;
}
.card-container {
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 25.52px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: You can adjust the margin to control spacing */
}