.privacy-policy-main{
    padding: 50px 40px;

    h3{
        color: rgba(31, 31, 31, 1);
        font-family: Space Grotesk;
font-size: 24px;
font-weight: 500;
line-height: 30.62px;
text-align: left;


    }
}