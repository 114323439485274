.add-art-work-container {
  padding: 24px;
  // margin: 24px;
  width: 100%;
  background: white;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  // margin-top: 90px;
  // margin-bottom: 90px;
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 30.62px;
  }
  form {
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 14px;
      .row {
        display: flex;
        flex-direction: row;
        .input-wrapper {
          width: unset;
          flex: 1;
          margin-top: 10px;
          .input-inner-wrapper {
            display: flex;
            border: 1px solid #d0d5dd;
            border-radius: 4px;
            align-items: center;

            div{
              width: 100%;
            }

            input {
              flex: 1;
              border: none;
            }
            span {
              padding: 0px 10px;
              font-weight: 600;
              color: black;
            }
          }
        }
      }
      .upload-file {
        .images-container{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 12px;
          img{
            height: 90px;
            border-radius: 8px;
            margin-top: 4px;
          }
          .upload-file-button {
            font-size: 12px;
            font-weight: 500;
            line-height: 15.31px;
            padding: 30px;
            gap: 0px;
            border-radius: 6px;
            border: 1px solid #eaecf0;
            background: #ffffff;
            width: fit-content;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
          }
          }
      }
      .input-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .input-inner-wrapper {
          display: flex;
          border: 1px solid #d0d5dd;
          border-radius: 4px;
          align-items: center;

          input {
            flex: 1;
            border: none;
          }
          span {
            padding: 0px 10px;
            font-weight: 600;
            color: #838799;
          }
        }
        .upload-file-button {
          background: #d0d5dd;
          padding: 12px 16px 12px 16px;
          border-radius: 4px;
          width: fit-content;
        }
        label {
          font-size: 16px;
          font-weight: 700;
          line-height: 20.42px;
        }
        input,
        textarea,
        select {
          padding: 12px 16px 12px 16px;
          border-radius: 4px;
          border: 1px solid #d0d5dd;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.42px;
          height: 48px;
          
        }
        textarea{
          height: 100px;
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        text-align: center;
      }
    }
  }
}
