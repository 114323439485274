@import "../../../../../App.scss";
.sidebar {
  min-width: 260px;
  .sidebar-options {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 12px;
    align-content: flex-start;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    width: fit-content;
    background-color: #ffffff;
    height: 100%;
    z-index: 1;
    .option-inner-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-content: flex-start;
      .chat {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.42px;
      }
      .logo {
        padding: 35px 0px;
        display: flex;
        justify-content: center;
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.42px;

        gap: 10px;
        background: #f9fafb;

        cursor: pointer;
        padding: 8px 12px;
        border-radius: 0.2rem;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: $color-primary;
          color: $color-secondary;
          svg {
            // fill: white;
            path {
              fill: white;
            }
          }
        }
        .icon {
          font-size: 1.6rem;
        }
      }
      .active {
        background-color: $color-primary;
        color: $color-secondary;
        svg {
          fill: white;
          path {
            fill: white;
          }
        }
      }
    }
    .logout-support {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .logout {
        display: flex;
        gap: 10px;
        color: #e31e24;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.42px;
        cursor: pointer;

        svg {
          path {
            fill: #e31e24;
          }
        }
      }
    }
  }
}
