@import "../../../App.scss";
.category-modal {
  display: flex;
  flex-direction: column;
  width: min(400px, 100%);
  padding: 24px !important;

  .close {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    svg {
      cursor: pointer;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.52px;
    text-align: start;
    margin-bottom: 24px;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.3rem;
      label {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.42px;
      }
      input,
      textarea {
        outline: none;
        width: 100%;
        border: 1px solid #EAECF0;
        padding: 0.5rem;
        border-radius: 4px;

        font-size: 16px;
        font-weight: 400;
        line-height: 20.42px;
      }
      @media (min-width: 451px) {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          grid-column: 1 / span 2;
        }
      }
    }
    .buttons {
      margin-top: 0;
      display: flex;
      gap: 1rem;
      justify-content: center;
      button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;
        padding: 8px 0px !important ;

        width: 100%;
      }
    }
  }
}
.category-modals {
  .modal-dialog {
    justify-content: center;
    .modal-content {
      width: min(400px, 100%);
    }
  }
}
