@import "../../App.scss";

.footer-container {
  background-color: #eaded2;
  display: flex;
  flex-direction: column;
  // grid-template-columns: repeat(1, 1fr);
  // gap: flex;
  border-top: 1px solid #c2c2c2;
  font-size: 1.1rem;
  padding: 130px 30px 30px;
  .row-1 {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
    /* gap: 7vw; */
    justify-content: space-between;
    .left-column {
      width: 500px;
      display: flex;
      gap: 24px;
      flex-direction: column;

      button {
        width: 200px;
        // height: 36px;
        padding: 8px 24px 8px 24px;
        gap: 10px;
        opacity: 0px;
        background: #333333;
        color: #ffffff;
        font-family: Space Grotesk;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.42px;
        text-align: left;
      }
      span {
        display: flex;
        flex-direction: column;
        font-family: Space Grotesk;
        font-size: 50px;
        font-weight: 500;
        line-height: 76.56px;
        text-align: left;
        width: min(500px, 100%);
      }
    }
    .above-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 30px;
      width: min(730px, 100%);

      &:nth-child(2),
      &:nth-child(3) {
        justify-self: flex-end;
      }

      .nav-link {
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 160px;
        span:first-of-type {
          font-size: 1.2rem;
          // text-decoration: underline;
          text-underline-offset: 0.5rem;
          font-weight: 500;
          // padding: 0px;
        }
        .line {
          height: 1px;
          width: 100%;
          background: #1f1f1f;
          margin-bottom: 12px;
        }
        span {
          font-size: 1.1rem;
          // padding: 12px 0px;
        }
      }
      .pointer{
        span{
          cursor: pointer
        }
      }
    }
  }
  .row-2 {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: end;
    position: relative;
    img {
      position: absolute;
      width: min(280px, 23vw);
      left: 50%;
      bottom: -30px;
      transform: translateX(-50%);
    }
  }
  span {
    justify-self: center;
    font-size: 1.1rem;
  }
}

@media (max-width: 800px) {
  .footer-container {
    .row-2 {
      .footer-img {
        img {
          width: min(280px, 25vw);
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .footer-container {
    .row-2 {
      .footer-img {
        img {
          width: min(280px, 30vw);
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .footer-container {
    .row-1 {
      .left-column {
        span {
          font-size: 20px;
          width: 100%;
          font-family: Space Grotesk;
          font-size: 20px;
          font-weight: 700;
          line-height: 25.52px;
          text-align: left;
        }
      }
      .above-footer {
        div {
          width: fit-content;
        }
      }
    }
    .row-2 {
      height: 20px;

      justify-content: center;
      .footer-img {
        img {
          width: min(280px, 40vw);
          right: 0px;
          left: unset;
          bottom: 20px;
          transform: unset;
        }
      }
    }
  }
}
