.checkout-address-div {
  margin: 1rem 0;
  cursor: pointer;
  .address-side {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // border: 1px solid #c2c2c270;
    border-radius: 0.5rem;
    @media (min-width: 521px) {
      // width: 55vw;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.1rem;
      padding: 1rem;
      .inputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        @media (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .input-box {
          display: flex;
          flex-direction: column;
          &:last-of-type {
            grid-column: 1 / span 2;
            @media (max-width: 600px) {
              grid-column: auto;
            }
          }
          input {
            outline: none;
            border: 1px solid #c2c2c2;
            padding: 0.5rem;
            border-radius: 0.2rem;
          }
        }
      }
      .buttons {
        display: flex;
        gap: 1rem;
        button {
          width: fit-content;
          align-self: flex-start;
          padding-inline: 2rem !important;
        }
      }
    }
    .delivery-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      font-size: 1.2rem;
      gap: 10px;
      .details {
        display: flex;
        flex-direction: column;
        color: #1f1f1f;
        flex: 1;
        gap: 6px;
        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          padding: 0;
          border-bottom: 0;
        }
        p {
          margin: 0;
          font-size: 12px;
          font-weight: 5400;
          line-height: 16px;
        }
      }
      .address-options {
        display: flex;
        gap: 1rem;
        width: fit-content;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.42px;

        span:first-of-type {
          color: #467fe4;
          cursor: pointer;
        }
        span:last-of-type {
          color: #ad0101;
          cursor: pointer;
        }
      }
    }
    .selected {
      background-color: #f5bf7db0;
    }
  }
}
